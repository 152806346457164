import React from 'react';
import "golfstatus_react_components/dist/index.css";
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import GolfstatusLogin from "./components/golfstatus-login";
import {defaultLayouts, useMediaQuery} from "golfstatus_react_components";
import {useSelector} from "react-redux";
import {selectTheme} from "./reducers/appSlice";

function App() {
  const { innerHeight } = useMediaQuery();
  const theme = useSelector(selectTheme);

  return (
    <div className={`App ${theme}`} style={{ ...defaultLayouts.fullWidth, height: innerHeight }}>
      <Router>
        <GolfstatusLogin></GolfstatusLogin>
      </Router>
    </div>
  );
}

export default App;
