import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {IntercomProvider} from "react-use-intercom";

const root = ReactDOM.createRoot(document.getElementById('root'));
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

root.render(
  // <React.StrictMode>
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
      <Provider store={store}>
        <App />
      </Provider>
    </IntercomProvider>
  // </React.StrictMode>
);

// need to load the script with the score key manually
let script = document.createElement('script');
script.type = 'text/javascript';
script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_CAPTCHA_SCORE_KEY}&badge=bottomleft`;
document.head.appendChild(script);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
