import axios from "axios";
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

const gsApi = axios.create({});

gsApi.interceptors.request.use(
  async function(config) {
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/vnd.api+json";

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

gsApi.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.data) {
      return new Promise(function(resolve, reject) {
        resolve(response.data.data);
      });
    } else {
      return response;
    }
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default gsApi;

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
