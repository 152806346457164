import {
  defaultLayouts,
  validations,
} from "golfstatus_react_components";
import React from "react";
import {faEye} from "@fortawesome/free-solid-svg-icons";

export const getSignInFormSections = (context) => {
  if (context) {
    const signInDetails = {
      type: "vertical x-large-gap",
      fields: [
        {
          ...context.setBinding("email"),
          style: { ...defaultLayouts.fullWidth },
          label: "Email Address",
          placeholder: "Email Address",
          isEditable: true,
          required: true,
          validation: {isValid: validations.validEmail, invalidLabel: "Please enter a valid email address."},
          onSubmit: context.onSubmit,
        },
        {
          ...context.setBinding("password"),
          style: { ...defaultLayouts.fullWidth },
          type: context.showPassword ? "text" : "password",
          label: "Password",
          placeholder: "Password",
          isEditable: true,
          required: true,
          rightIcon: faEye,
          onSubmit: context.onSubmit,
          rightIconClick: () => { context.togglePasswordVisibility() },
        },
        {
          ...context.getHelp?.(),
          customView: true,
        },
        {
          ...context.getSubmitButton?.(),
          customView: true,
        },
      ],
    };

    return [signInDetails];
  }
};

export const getCreateAccountFormSections = (context) => {
  if (context) {
    const createAccountDetails = {
      type: "vertical x-large-gap",
      fields: [
        {
          ...context.setBinding("first_name"),
          style: { ...defaultLayouts.fullWidth },
          label: "First Name",
          placeholder: "First Name",
          isEditable: true,
          required: true,
          onSubmit: context.onSubmit,
        },
        {
          ...context.setBinding("last_name"),
          style: { ...defaultLayouts.fullWidth },
          label: "Last Name",
          placeholder: "Last Name",
          isEditable: true,
          required: true,
          onSubmit: context.onSubmit,
        },
        {
          ...context.setBinding("email"),
          style: { ...defaultLayouts.fullWidth },
          label: "Email Address",
          placeholder: "Email Address",
          isEditable: true,
          required: true,
          onSubmit: context.onSubmit,
          validation: {
            isValid: validations.validEmail,
            invalidLabel: "Please enter a valid email address."
          },
        },
        {
          ...context.setBinding("phone"),
          style: { ...defaultLayouts.fullWidth },
          label: "Phone Number",
          placeholder: "Phone Number",
          isEditable: true,
          required: true,
          onSubmit: context.onSubmit,
          validation: {
            isValid: validations.validPhone,
            invalidLabel: "Please enter a valid phone number."
          },
        },
        {
          ...context.setBinding("password"),
          style: { ...defaultLayouts.fullWidth },
          type: context.showPassword ? "text" : "password",
          label: "Password",
          placeholder: "Password",
          isEditable: true,
          required: true,
          rightIcon: faEye,
          onSubmit: context.onSubmit,
          rightIconClick: () => { context.togglePasswordVisibility() },
          validation: {
            isValid: (pass) => pass && pass.length >= 6,
            invalidLabel: "Password must be at least 6 characters."
          },
        },
        {
          ...context.setBinding("password_confirmation"),
          style: { ...defaultLayouts.fullWidth },
          type: context.showConfirmPassword ? "text" : "password",
          label: "Confirm New Password",
          placeholder: "Password",
          isEditable: true,
          required: true,
          rightIcon: faEye,
          onSubmit: context.onSubmit,
          rightIconClick: () => { context.toggleConfirmPasswordVisibility() },
          validation: {
            isValid: () => context.createAccountDetails?.password === context.createAccountDetails?.password_confirmation,
            invalidLabel: "Your passwords must match.",
          }
        },
        {
          ...context.tosToggle?.(),
          customView: true,
          required: true,
        },
        {
          value: context?.getCaptcha,
          style: { alignItems: "flex-start" },
          customView: true,
        },
        {
          ...context.createAccountButton?.(),
          customView: true,
        },
      ],
    };

    return [createAccountDetails];
  }
};

export const getResetPasswordFormSections = (context) => {
  if (context) {
    const resetPasswordDetails = {
      type: "vertical xx-large-gap",
      fields: [
        {
          value: (<div>Enter your email address and we'll send you a link to change your password.</div>),
          isCustom: true,
        },
        {
          ...context.setBinding("email"),
          style: { ...defaultLayouts.fullWidth },
          label: "Email Address",
          placeholder: "Email Address",
          isEditable: true,
          required: true,
          onSubmit: context.onSubmit,
          validation: {isValid: validations.validEmail, invalidLabel: "Please enter a valid email address."},
        },
        {
          value: context?.getCaptcha,
          style: { alignItems: "flex-start" },
          customView: true,
        },
        {
          value: context.furtherHelp(),
          isCustom: true,
        },
      ],
    };

    return [resetPasswordDetails];
  }
};

export const getChangePasswordFormSections = (context) => {
  if (context) {
    const changePasswordDetails = {
      type: "vertical x-large-gap",
      fields: [
        {
          ...context.setBinding("password"),
          style: { ...defaultLayouts.fullWidth },
          type: context.showPassword ? "text" : "password",
          label: "New Password",
          placeholder: "Password",
          isEditable: true,
          required: true,
          rightIcon: faEye,
          onSubmit: context.onSubmit,
          rightIconClick: () => { context.togglePasswordVisibility() },
          validation: {isValid: (pass) => pass && pass.length >= 6, invalidLabel: "Password must be at least 6 characters."},
        },
        {
          ...context.setBinding("password_confirmation"),
          style: { ...defaultLayouts.fullWidth },
          type: context.showConfirmPassword ? "text" : "password",
          label: "Confirm New Password",
          placeholder: "Password",
          isEditable: true,
          required: true,
          rightIcon: faEye,
          onSubmit: context.onSubmit,
          rightIconClick: () => { context.toggleConfirmPasswordVisibility() },
          validation: {
            isValid: () => context.changePasswordDetails?.password === context.changePasswordDetails?.password_confirmation,
            invalidLabel: "Your passwords must match.",
          }
        },
        {
          ...context.getSubmitButton?.(),
          customView: true,
        },
      ],
    };

    return [changePasswordDetails];
  }
};
