import React from 'react';
import './cookie-policy.scss';
import {defaultPadding, golfstatusColors, GSButton, GSInfoGroup} from "golfstatus_react_components";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";

const CookiePolicy = ({handleAccept}) => {
  const getCookieTrayDataGroups = () => {
    return [
      {
        title: <GSButton buttonIcon={faCircleExclamation} title={"Cookie Policy"} isFocusable={false} style={{ padding: "8px 0", justifyContent: "flex-start", cursor: "default" }} />,
        style: { maxWidth: "986px" },
        sections: [
          {
            sectionItems: [
              {
                type: "secondary",
                value: "We use cookies, including from our partners, to enhance and personalize your experience when browsing our website. By using our website, you consent to these cookies. For more information on cookies, including how to manage your consent, refer to our Privacy Policy.",
              },
            ],
          },
        ],
      },
      {
        type: "cookie-btn-container",
        sections: [
          {
            sectionItems: [
              {
                type: "primary cookie-btn",
                value: <GSButton title={"Agree"} style={{ width: "100%" }} type={"grey"} onClick={ () => handleAccept() } />,
              },
            ],
          },
        ],
      },
    ];
  };

  return (
    <cookie-policy>
      <GSInfoGroup
        dataGroups={getCookieTrayDataGroups()}
        style={{
          ...defaultPadding.xLargePad,
          position: "sticky",
          bottom: 0,
          backgroundColor: golfstatusColors.white,
          borderTop: `2px solid ${golfstatusColors.grey100}`
        }}
        listType={"mobile-vertical cookie-tray"}
      />
    </cookie-policy>
  );
};

export default CookiePolicy;
