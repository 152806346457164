import {getNotificationItemInfo} from "../helpers/Converters";
import {mediumDelay} from "../helpers/JSONapi";
import {faCheck, faExclamationCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";

export const baseURL = `${process.env.REACT_APP_GOLFSTATUS_BASE_API_URL}`;
export const v1URL = `${baseURL}/v1`;
export const v2URL = `${baseURL}/v2`;
export const validateURL = `${v2URL}/users/validate`;
export const authenticateURL = `${v2URL}/authenticate`;
export const createAccountURL = `${v2URL}/users`;
export const changePasswordRequestURL = `${v2URL}/users/change_password`;
export const resetPasswordURL = `${v1URL}/users/reset_password`;
export const tmsQuickStartURL = `${process.env.REACT_APP_GOLFSTATUS_TMS_APP_URL}/tournaments/quick-start`;

export const messages = {
  genericError: "Unable to authenticate",
  incorrectCredentials: "This email address and password combination is incorrect.",
  resetEmailFailure: "An error occurred while attempting to deliver the email.",
  passwordError: "This password could not be saved. Passwords must be at least 6 characters.",
  userBanned: "There is an issue with your account. Please contact GolfStatus' support team.",
  resetTokenFailure: "This password reset link has expired.",
  resetPasswordEmailSent: (email) => { return `Use the sign-in link we sent to ${email} to reset your password.` },
  resetPasswordEmailNotFound: "There is no account associated with this email address.",
  changePasswordSuccess: "Your password has been successfully changed.",
};

/**
 * create a notification for the app
 *
 * @param {String} message message of the notification
 * @param {String} icon icon for notification
 * @param {String} state state of notification [success, grey, error]
 * @param {String} result result of the notification [pending, fulfilled, rejected, unknown]
 * @param {String} timeout timeout for the notification to expire
 * @param {String} actions actions for the notification
 * @param {String} id id for the notification
 * @param {String} error error for the notification
 *
 * @returns {object} notification
 */
export const createNotification = (
  message,
  icon,
  state,
  result,
  timeout,
  actions,
  id,
  error,
) => {
  return {
    message,
    header: getNotificationItemInfo(message ?? "", icon),
    state: state ?? "grey",
    result: result ?? "unknown",
    timeout: timeout ?? 0,
    pageActions: actions ?? [],
    id,
    error,
  }
}

const getName = (thunk) => {
  if (thunk?.typePrefix?.includes?.("/")) {
    return thunk?.typePrefix?.split?.("/")?.[1];
  } else {
    return thunk.typePrefix;
  }
};

export const defaultNotificationSettings = {
  pending: {
    icon: faSpinner,
    message: (state, action) => "pending",
    state: "grey",
    result: "pending",
    timeout: 0,
    actions: [],
    meta: {},
  },
  fulfilled: {
    icon: faCheck,
    message: (state, action) => "fulfilled",
    state: "success",
    result: "fulfilled",
    timeout: mediumDelay,
    actions: [],
    meta: {},
  },
  rejected: {
    icon: faExclamationCircle,
    message: (state, action) => "rejected",
    state: "warning",
    result: "rejected",
    timeout: 0,
    actions: [],
    meta: {},
  },
};

export const getThunkResponse = (builder, thunk, callback) => {
  const notifications = { rejected: defaultNotificationSettings.rejected };
  return thunkResponse(builder, thunk, callback, notifications);
};

export const thunkResponse = (
  builder,
  thunk,
  callback,
  notificationSettings
) => {
  const { pending, fulfilled, rejected } = notificationSettings ?? defaultNotificationSettings;

  const thunkName = getName(thunk);

  return (
    builder.addCase(thunk.pending, (state, action) => {
      state.loading.push(thunkName);
      if (state?.notifications && pending) {
        state.notifications = [
          createNotification(
            pending?.message(state, action),
            pending?.icon,
            pending.state,
            pending?.result,
            pending?.timeout,
            pending?.actions,
            thunkName,
            action.meta,
          ),
        ];
      }
    }),
    builder.addCase(thunk.fulfilled, (state, action) => {
      state.loading = state.loading.filter((f) => f !== thunkName);
      if (state?.notifications && fulfilled) {
        state.notifications = [
          createNotification(
            fulfilled?.message(state, action),
            fulfilled?.icon,
            fulfilled.state,
            fulfilled?.result,
            fulfilled?.timeout,
            fulfilled?.actions,
            thunkName,
            action.meta,
          ),
        ];
      }
      callback?.(state, action);
    }),
    builder.addCase(thunk.rejected, (state, action) => {
      state.loading = state.loading.filter((f) => f !== thunkName);
      if (state?.notifications && rejected) {
        state.notifications = [
          createNotification(
            rejected?.message(state, action),
            rejected?.icon,
            rejected.state,
            rejected?.result,
            rejected?.timeout,
            rejected?.actions,
            thunkName,
            action.meta,
          ),
        ];
      }
    })
  );
};
