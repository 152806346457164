import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import gsApi, {firebaseAuth} from "../auth/auth";
import {
  authenticateURL,
  changePasswordRequestURL,
  createAccountURL, defaultNotificationSettings, messages,
  resetPasswordURL,
  thunkResponse,
  validateURL
} from "../app/api";
import {getAuth, OAuthProvider, signInWithCustomToken, signInWithPopup} from "firebase/auth";
import {longDelay, mediumDelay} from "../helpers/JSONapi";
import {setAuthenticationCookie} from "../app/gs-session";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";

const authenticateWithFirebase = async (firebaseToken) => {
  const firebaseUser = await signInWithCustomToken(firebaseAuth, firebaseToken);
  return firebaseUser.user.getIdToken();
};

const authenticateWithGolfstatus = async (gsUser) => {
  return gsApi.post(authenticateURL, gsUser, {});
};

export const authenticateUser = createAsyncThunk(
  "app/authenticateUser",
  async (credentials, thunkAPI) => {
    try {
      const config = {
        headers: {
          Authorization: "Basic " + btoa(credentials.email + ":" + credentials.password),
        },
      };
      const user = await gsApi.post(validateURL, { captcha_data: credentials.captcha_data }, config);

      const userToken = await authenticateWithFirebase(user.attributes['firebase_token']);

      let gsUser = {
        uid: user['id'],
        email: user.attributes['email'],
        first_name: user.attributes['first-name'],
        last_name: user.attributes['last-name'],
        source: 'web',
        user_token: userToken,
      };
      const result = await authenticateWithGolfstatus(gsUser);

      gsUser.firebase_token = user.attributes['firebase_token'];
      gsUser.user_class = user.attributes['user-class'];
      gsUser.golfstatusToken = result.attributes['golfstatus-token'];
      gsUser.avatar = result.attributes['avatar'];

      return gsUser;
    } catch (error) {
      const message = error.status === 401 ? messages.incorrectCredentials : error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authenticateUserWithApple = createAsyncThunk(
  "app/authenticateUserWithApple",
  async (data, thunkAPI) => {
    const auth = getAuth();
    const apple = new OAuthProvider('apple.com');
    apple.addScope("email");
    apple.addScope("name");

    try {
      const appleResult = await signInWithPopup(auth, apple);
      const firebaseToken = await appleResult.user.getIdToken();
      const credential = OAuthProvider.credentialFromResult(appleResult);
      const idToken = credential.idToken;
      const userData = JSON.parse(atob(idToken.split('.')[1]));

      let gsUser = {
        uid: appleResult.user.uid,
        email: userData.email,
        source: 'web',
        user_token: firebaseToken,
      };
      const result = await authenticateWithGolfstatus(gsUser);

      gsUser.uid = result.id;
      gsUser.firebase_token = firebaseToken;
      gsUser.user_class = result.attributes['user-class'];
      gsUser.golfstatusToken = result.attributes['golfstatus-token'];
      gsUser.avatar = result.attributes['avatar'];

      return gsUser;
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        return thunkAPI.rejectWithValue("Apple sign in cancelled.");
      }
      const message = error.message ? error.message : error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAccount = createAsyncThunk(
  "app/createAccount",
  async (accountDetails, thunkAPI) => {
    try {
      const newAccount = await gsApi.post(createAccountURL, accountDetails, {});

      const userToken = await authenticateWithFirebase(newAccount.attributes['firebase-token']);

      let gsUser = {
        uid: newAccount.id,
        email: newAccount.attributes['email'],
        first_name: newAccount.attributes['first-name'],
        last_name: newAccount.attributes['last-name'],
        source: 'web',
        user_token: userToken,
      };
      const result = await authenticateWithGolfstatus(gsUser);

      gsUser.firebase_token = newAccount.attributes['firebase-token'];
      gsUser.user_class = newAccount.attributes['user-class'];
      gsUser.golfstatusToken = result.attributes['golfstatus-token'];
      gsUser.avatar = result.attributes['avatar'];

      return gsUser;
    } catch (error) {
      let message = '';
      const err = error.response.data.error;
      if (typeof err === 'string') {
        message = err;
      } else {
        let errorString = '';
        for (const [key, value] of Object.entries(err)) {
          errorString += `${key[0].toUpperCase() + key.slice(1)} ${value}. `;
        }
        message = errorString;
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendResetPasswordEmail = createAsyncThunk(
  "app/sendResetPasswordEmail",
  async (resetPasswordData, thunkAPI) => {
    try {
      return await gsApi.post(changePasswordRequestURL, resetPasswordData, {});
    } catch (error) {
      let message = '';
      if (error.status === 404) {
        message = messages.resetPasswordEmailNotFound;
      } else {
        message = messages.resetEmailFailure;
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "app/changePassword",
  async (changePasswordData, thunkAPI) => {
    try {
      return await gsApi.put(resetPasswordURL, changePasswordData, {});
    } catch (error) {
      let message = '';
      if (error.status === 400) {
        message = messages.passwordError;
      } else if (error.status === 403) {
        message = messages.userBanned;
      } else if (error.status === 404) {
        message = messages.resetTokenFailure;
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const appSlice = createSlice({
  name: "app",
  initialState: {
    referrer: '',
    signInData: {
      email: '',
      password: '',
    },
    createAccountData: {
      first_name: '',
      last_name: '',
      email: '',
      username: '',
      phone: '',
      password: '',
      password_confirmation: '',
      tos_accepted: false,
      invitation_token: '',
    },
    resetPasswordData: {
      email: '',
    },
    changePasswordData: {
      password: '',
      password_confirmation: '',
      reset_password_token: '',
    },
    captchaData: {
      captcha_token: '',
      captcha_action: '',
      is_score: false,
    },
    loading: [],
    notifications: [],
    theme: "light",
  },
  reducers: {
    setReferrer: (state, action) => {
      state.referrer = action.payload;
    },
    setResetPasswordData: (state, action) => {
      state.resetPasswordData = action.payload;
    },
    setSignInData: (state, action) => {
      state.signInData = action.payload;
    },
    setCreateAccountData: (state, action) => {
      state.createAccountData = action.payload;
    },
    updateCreateAccountData: (state, action) => {
      state.createAccountData = { ...state.createAccountData, ...action.payload };
    },
    setChangePasswordData: (state, action) => {
      state.changePasswordData = action.payload;
    },
    setCaptchaData: (state, action) => {
      state.captchaData = { ...state.captchaData, ...action.payload };
    },
    addNotification: (state, action) => {
      state.notifications = [...state.notifications, action.payload];
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    thunkResponse(builder, authenticateUser, async (state, action) => {
      setAuthenticationCookie(action.payload, process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN);
    },
    {
      ...defaultNotificationSettings,
      pending: false,
      rejected: {
        ...defaultNotificationSettings.rejected,
        message: (state, action) => {
          return action?.payload;
        },
      }
    });
    thunkResponse(builder, authenticateUserWithApple, async (state, action) => {
      setAuthenticationCookie(action.payload, process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN);
    },
    {
      ...defaultNotificationSettings,
      pending: false,
      rejected: {
        ...defaultNotificationSettings.rejected,
        message: (state, action) => {
          return action?.payload;
        },
        timeout: mediumDelay,
      }
    });
    thunkResponse(builder, createAccount, async (state, action) => {
      setAuthenticationCookie(action.payload, process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN);
    },
    {
      ...defaultNotificationSettings,
      pending: false,
      rejected: {
        ...defaultNotificationSettings.rejected,
        message: (state, action) => {
          return action?.payload;
        },
      }
    });
    thunkResponse(builder, sendResetPasswordEmail, async (state, action) => {
      state.resetPasswordData.email = '';
    },
    {
      ...defaultNotificationSettings,
      fulfilled: {
        ...defaultNotificationSettings.fulfilled,
        message: (state, action) => {
          return messages.resetPasswordEmailSent(state.resetPasswordData.email);
        },
        icon: faPaperPlane,
        timeout: longDelay,
      },
      rejected: {
        ...defaultNotificationSettings.rejected,
        message: (state, action) => {
          return action?.payload;
        },
      }
    });
    thunkResponse(builder, changePassword, async (state, action) => {
      state.signInData.email = action.payload.email;
    },
    {
      ...defaultNotificationSettings,
      pending: false,
      rejected: {
        ...defaultNotificationSettings.rejected,
        message: (state, action) => {
          return action?.payload;
        },
      }
    });
  }
});

export const {
  setReferrer,
  setResetPasswordData,
  setCreateAccountData,
  updateCreateAccountData,
  setChangePasswordData,
  setSignInData,
  setCaptchaData,
  addNotification,
  clearNotifications,
} = appSlice.actions;

export const selectReferrer = (state) => state.app.referrer;
export const selectNotifications = (state) => state.app.notifications;
export const selectCreateAccountData = (state) => state.app.createAccountData;
export const selectSignInData = (state) => state.app.signInData;
export const selectResetPasswordData = (state) => state.app.resetPasswordData;
export const selectChangePasswordData = (state) => state.app.changePasswordData;
export const selectCaptchaData = (state) => state.app.captchaData;
export const selectTheme = (state) => state.app.theme;
export const selectLoading = (state) => state.app.loading;

export default appSlice.reducer;
