import React, {useEffect, useState} from 'react';
import './change-password.scss';
import {
  defaultGapping,
  defaultLayouts, defaultPadding,
  defaultTypography, golfstatusTypography, GSButton,
  GSForm, GSListPage, GSLoadingSpinnerOverlay, useFormDataValidation,
} from 'golfstatus_react_components';
import {getChangePasswordFormSections} from "../forms/LoginForms";
import {useDispatch, useSelector} from "react-redux";
import {
  addNotification,
  authenticateUser,
  changePassword, clearNotifications,
  selectChangePasswordData,
  selectLoading, selectNotifications, selectReferrer, selectSignInData,
  setChangePasswordData
} from "../reducers/appSlice";
import {useTheme} from "../hooks/themeHooks";
import {useIntercom} from "react-use-intercom";
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import {createNotification} from "../app/api";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {longDelay} from "../helpers/JSONapi";
import {useNotificationAction} from "../hooks/notificationHooks";

const ChangePassword = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, , getStyle] = useTheme();
  const { show } = useIntercom();

  const loading = useSelector(selectLoading);
  const notifications = useSelector(selectNotifications);
  const changePasswordDetails = useSelector(selectChangePasswordData);
  const signInDetails = useSelector(selectSignInData);
  const referrer = useSelector(selectReferrer);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [redirecting, setRedirecting] = useState(false);


  // Set context
  const [context, isValid] = useFormDataValidation({
    initialValid: false,
    data: changePasswordDetails,
    setData: (updatedDetails) => dispatch(setChangePasswordData(updatedDetails)),
  });
  context.changePasswordDetails = changePasswordDetails;
  context.getSubmitButton = () => {
    return {
      value: (<GSButton title={"Change Password"} type={"black"} isDisabled={!isValid} onClick={changeUserPassword} isFocusable={true} />)
    };
  };
  context.showPassword = showPassword;
  context.togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  context.showConfirmPassword = showConfirmPassword;
  context.toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  context.onSubmit = () => {
    if (isValid) {
      changeUserPassword();
    }
  };

  // Effects
  useEffect(() => {
    const resetPasswordToken = searchParams.get("reset_password_token");
    if (resetPasswordToken) {
      dispatch(setChangePasswordData({ reset_password_token: resetPasswordToken }));
    } else {
      dispatch(addNotification(createNotification(
        "The reset password token is invalid. Please request a new one.",
        faExclamationCircle,
        "warning",
        "unknown",
        longDelay,
        [],
        "invalidResetPasswordToken",
      )));
      navigate("/reset-password");
    }
  }, [dispatch, navigate, searchParams]);

  // hide the reCAPTCHA badge
  useEffect(() => {
    const captchaBadge = window.document.getElementsByClassName('grecaptcha-badge');
    if (captchaBadge[0]) {
      captchaBadge[0]['style']['display'] = 'none';
    }
  }, []);

  // Notification hooks
  useNotificationAction("fulfilled", notifications, () => logUserIn(), "changePassword");
  useNotificationAction("rejected", notifications, () => setRedirecting(false), "changePassword");
  useNotificationAction("fulfilled", notifications, () => window.location.replace(referrer), "authenticateUser");
  useNotificationAction("rejected", notifications, () => setRedirecting(false), "authenticateUser");

  // Methods and content
  const changeUserPassword = () => {
    setRedirecting(true);
    dispatch(changePassword(changePasswordDetails));
  };

  const logUserIn = () => {
    dispatch(clearNotifications());
    // eslint-disable-next-line no-undef
    grecaptcha.enterprise.ready(async () => {
      // eslint-disable-next-line no-undef
      const captcha_token = await grecaptcha.enterprise.execute(process.env.REACT_APP_CAPTCHA_SCORE_KEY, { action: 'LOGIN' });
      dispatch(authenticateUser({
        email: signInDetails.email,
        password: changePasswordDetails.password,
        captcha_data: {
          captcha_token: captcha_token,
          captcha_action: 'LOGIN',
          is_score: true,
        }
      }));
    });
  };

  const getTitle = () => {
    return (
      <div style={{ ...defaultLayouts.vStack, ...defaultGapping.mediumGap }}>
        <div style={{ ...defaultTypography.displaySmall }}>Change Password</div>
        <div style={{ ...defaultLayouts.hStack, ...defaultGapping.smallGap, flexWrap: "wrap" }}>
          <span style={{ ...defaultTypography.tertiary, fontWeight: golfstatusTypography.regularFontWeight }}>Still having troubles getting signed in? </span>
          <div style={{ ...defaultTypography.body, ...getStyle(theme.secondary), cursor: "pointer", textDecoration: "underline", textUnderlineOffset: "2px" }} onClick={show}>Get Help.</div>
        </div>
      </div>
    );
  };

  const isLoading = () => {
    return loading.length > 0 || redirecting;
  };

  const getContent = () => {
    return isLoading() ? (
      <div key={"spinner"} style={{...defaultLayouts.vStack.align("center", "center"), ...defaultLayouts.fill}}>
        <GSLoadingSpinnerOverlay spinnerSize={"xLarge"} mainText={"Loading..."} />
      </div>
    ) : (
      <GSForm
        style={{ ...defaultPadding.xLargePad }}
        formTitle={getTitle()}
        formSections={getChangePasswordFormSections(context)}
      ></GSForm>
    );
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getChangePasswordPage = () => {
    return {
      getItemList: getContent,
      getRouter: getRouter,
      headerStyle: { ...defaultLayouts.hidden },
      style: { maxWidth: "480px", ...defaultLayouts.fullWidth },
    };
  };

  const getLayoutStyle = () => {
    return isLoading()
      ? {
        ...defaultLayouts.vStack.align("center", "center"),
        ...defaultLayouts.fill,
      } : {
        ...defaultLayouts.vStack.align("center", "flex-start"),
        ...defaultLayouts.fullWidth,
        ...defaultPadding.xxLargePad.apply("vertical"),
      };
  };

  return (
    <change-password style={ getLayoutStyle() }>
      <GSListPage { ...getChangePasswordPage() } />
    </change-password>
  );
};

export default ChangePassword;
