import React, {useMemo} from "react";
import "./reset-password.scss";
import {
  defaultGapping,
  defaultLayouts, defaultTypography, golfstatusTypography, GSCaptchaCheckbox,
  GSForm,
  GSSidePanelPage,
  useFormDataValidation
} from "golfstatus_react_components";
import {useIntercom} from "react-use-intercom";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {
  clearNotifications, selectCaptchaData,
  selectNotifications,
  selectResetPasswordData,
  sendResetPasswordEmail, setCaptchaData, setResetPasswordData
} from "../reducers/appSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation
} from "../hooks/notificationHooks";
import {getResetPasswordFormSections} from "../forms/LoginForms";
import {useTheme} from "../hooks/themeHooks";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, , getStyle] = useTheme();
  const { show } = useIntercom();

  const notifications = useSelector(selectNotifications);
  const resetPasswordDetails = useSelector(selectResetPasswordData);
  const captchaData = useSelector(selectCaptchaData);

  // Set context
  const [context, isValid] = useFormDataValidation({
    initialValid: false,
    data: resetPasswordDetails,
    setData: (updatedDetails) => dispatch(setResetPasswordData(updatedDetails)),
  });
  context.furtherHelp = () => {
    return (
      <div style={{ ...defaultLayouts.hStack, ...defaultGapping.smallGap, flexWrap: "wrap", ...defaultTypography.bodyRegular }}>
        <span>Need more help? </span>
        <span style={{ fontWeight: golfstatusTypography.boldFontWeight, ...getStyle(theme.secondary), cursor: "pointer", textDecoration: "underline", textUnderlineOffset: "2px" }} onClick={show}>Contact GolfStatus Support.</span>
      </div>
    );
  };
  context.getCaptcha = useMemo(() => {
    return (
      <GSCaptchaCheckbox
        captchaKey={process.env.REACT_APP_CHECKBOX_CAPTCHA_KEY}
        action='SIGNUP'
        setToken={(value) => dispatch(setCaptchaData({ captcha_action: 'PASSWORD_RESET', captcha_token: value }))}
      />
    );
  }, [dispatch]);
  context.onSubmit = () => {
    if (isValid) {
      resetPassword();
    }
  };

  // Notification hooks
  useNotificationNavigation("fulfilled", notifications, "/", "sendResetPasswordEmail");

  // Methods and content
  const resetPassword = () => {
    dispatch(sendResetPasswordEmail({ ...resetPasswordDetails, captcha_data: captchaData }));
  };

  const cancel = () => {
    dispatch(clearNotifications());
    dispatch(setResetPasswordData({ email: "" }));
    navigate("/");
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const leftNavigation = () => {
    dispatch(clearNotifications());
    dispatch(setResetPasswordData({ email: "" }));
    navigate(-1);
  };

  const getNavigation = () => {
    return {
      title: "Change Password",
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const isFormValid = () => {
    return isValid && Boolean(captchaData?.captcha_token);
  };

  let notificationSettings = {
    notifications: notifications,
    saveAction: resetPassword,
    bannerActions: getSaveBannerActions(sendResetPasswordEmail(), leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications] = useNotificationBanner(
    notificationSettings
  );

  const getContent = () => {
    return (
      <GSForm
        formTitle={ "Change Password" }
        formSections={ getResetPasswordFormSections(context) }
      ></GSForm>
    );
  };

  const getDrawerActions = () => {
    return [
      {
        name: "Send Link",
        isDisabled: !isFormValid(),
        action: () => resetPassword(),
        type: "black",
      },
      {
        name: "Cancel",
        action: () => cancel(),
        type: "grey",
      },
    ];
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  return (
    <reset-password>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </reset-password>
  );
};

export default ResetPassword;
