import React, {useEffect, useMemo, useState} from 'react';
import './create-account.scss';
import {
  defaultGapping,
  defaultLayouts, defaultPadding, defaultTypography, golfstatusTypography, GSButton, GSCaptchaCheckbox,
  GSForm, GSListPage, GSLoadingSpinnerOverlay, GSToggle, useFormDataValidation,
} from 'golfstatus_react_components';
import {useDispatch, useSelector} from "react-redux";
import {
  clearNotifications,
  createAccount, selectCaptchaData,
  selectCreateAccountData,
  selectLoading, selectNotifications, selectReferrer, setCaptchaData,
  setCreateAccountData, updateCreateAccountData,
} from "../reducers/appSlice";
import {getCreateAccountFormSections} from "../forms/LoginForms";
import {useTheme} from "../hooks/themeHooks";
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import {logOut} from "../app/gs-session";
import {useNotificationAction} from "../hooks/notificationHooks";

const CreateAccount = () => {
  const [theme, , getStyle] = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const CaT = searchParams?.get("cat") === "true";
  const returningUser = searchParams?.get("ru") === "true";
  const firstName = searchParams?.get("first_name") ?? "";
  const lastName = searchParams?.get("last_name") ?? "";
  const email = searchParams?.get("email") ?? "";
  const phone = searchParams?.get("phone") ?? "";
  const invitationToken = searchParams?.get("invitation_token") ?? "";

  const createAccountDetails = useSelector(selectCreateAccountData);
  const loading = useSelector(selectLoading);
  const notifications = useSelector(selectNotifications);
  const referrer = useSelector(selectReferrer);
  const captchaData = useSelector(selectCaptchaData);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  // Set context
  const [context, isValid] = useFormDataValidation({
    initialValid: false,
    data: createAccountDetails,
    setData: (updatedDetails) => dispatch(setCreateAccountData({ ...updatedDetails, username: updatedDetails.email })),
  });
  context.createAccountDetails = createAccountDetails;
  context.tosToggle = () => {
    return {
      description: (
        <div style={{ ...defaultLayouts.hStack, ...defaultGapping.smallGap, fontSize: golfstatusTypography.smallFont, flexWrap: "wrap" }}>
          <span>I have reviewed and accept GolfStatus'</span>
          <span style={{ ...defaultTypography.body, ...getStyle(theme.secondary), cursor: "pointer", textDecoration: "underline", textUnderlineOffset: "2px" }}
                onClick={ () => openLinkInTab("https://golfstatus.com/golfstatus-terms-of-service") }>Terms of Service</span>
          <span>and</span>
          <span style={{ ...defaultTypography.body, ...getStyle(theme.secondary), cursor: "pointer", textDecoration: "underline", textUnderlineOffset: "2px" }}
                onClick={ () => openLinkInTab("https://golfstatus.com/golfstatus-privacy-policy") }>Privacy Policy</span>.
        </div>
      ),
      descriptionStyle: {
        opacity: 1,
      },
      value: (
        <GSToggle
          {...context.setToggleBinding?.("tos_accepted")}
          trueDescription={"Yes"}
          falseDescription={"No"}
        />
      ),
      validation: {
        isValid: () => context.getData()?.tos_accepted === true,
        invalidLabel: "You must accept the Terms of Service and Privacy Policy to create an account.",
      },
      failedValidation: context.validationFailed,
    };
  };
  context.createAccountButton = () => {
    return {
      value: <GSButton title={"Create Account"} type={"black"} isDisabled={!isFormValid()} onClick={createAccountAndLogin} isFocusable={true} />,
    };
  };
  context.showPassword = showPassword;
  context.togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  context.showConfirmPassword = showConfirmPassword;
  context.toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  context.getCaptcha = useMemo(() => {
    return (
      <GSCaptchaCheckbox
        captchaKey={process.env.REACT_APP_CHECKBOX_CAPTCHA_KEY}
        action='SIGNUP'
        setToken={(value) => dispatch(setCaptchaData({ captcha_action: 'SIGNUP', captcha_token: value }))}
      />
    );
  }, [dispatch]);
  context.onSubmit = () => {
    if (isValid) {
      createAccountAndLogin();
    }
  };

  // Effects
  useEffect(() => {
    if (CaT) {
      if (returningUser) {
        logOut();
        searchParams.set("ru", "false");
        navigate(`/create?${searchParams.toString()}`);
      }
    }

    dispatch(updateCreateAccountData({
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      invitation_token: invitationToken,
    }));
  }, [dispatch, navigate, searchParams, CaT, returningUser, firstName, lastName, email, phone, invitationToken]);

  // hide the reCAPTCHA badge
  useEffect(() => {
    const captchaBadge = window.document.getElementsByClassName('grecaptcha-badge');
    if (captchaBadge[0]) {
      captchaBadge[0]['style']['display'] = 'none';
    }
  }, []);

  // Notification hooks
  useNotificationAction("fulfilled", notifications, () => window.location.replace(referrer), "createAccount");
  useNotificationAction("rejected", notifications, () => setRedirecting(false), "createAccount");

  // Methods and content
  const createAccountAndLogin = () => {
    setRedirecting(true);
    dispatch(createAccount({ ...createAccountDetails, captcha_data: captchaData }));
  };

  const isFormValid = () => {
    return isValid && Boolean(captchaData?.captcha_token);
  };

  const getTitle = () => {
    return (
      <div style={{ ...defaultLayouts.vStack, ...defaultGapping.mediumGap }}>
        <div style={{ ...defaultTypography.displaySmall }}>Create Account</div>
        <div style={{ ...defaultLayouts.hStack, ...defaultGapping.smallGap, flexWrap: "wrap" }}>
          <span style={{ ...defaultTypography.tertiary, fontWeight: golfstatusTypography.regularFontWeight }}>Already have a GolfStatus account? </span>
          <span style={{ ...defaultTypography.body, ...getStyle(theme.secondary), cursor: "pointer", textDecoration: "underline", textUnderlineOffset: "2px" }} onClick={() => goToSignIn()}>Sign In.</span>
        </div>
      </div>
    );
  };

  const goToSignIn = () => {
    dispatch(clearNotifications());
    navigate("/");
  };

  const openLinkInTab = (url) => {
    window.open(url, "_blank");
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const isLoading = () => {
    return loading.length > 0 || redirecting;
  };

  const getContent = () => {
    return isLoading() ? (
      <div key={"spinner"} style={{ ...defaultLayouts.vStack.align("center", "center"), ...defaultLayouts.fill }}>
        <GSLoadingSpinnerOverlay spinnerSize={"xLarge"} mainText={"Loading..."} />
      </div>
    ) : (
      <GSForm
        style={{ ...defaultPadding.xLargePad }}
        formTitle={ getTitle() }
        formSections={ getCreateAccountFormSections(context) }
      ></GSForm>
    );
  };

  const getCreateAccountPage = () => {
    return {
      getItemList: getContent,
      getRouter: getRouter,
      headerStyle: { ...defaultLayouts.hidden },
      style: { maxWidth: "480px", ...defaultLayouts.fullWidth },
    };
  };

  const getLayoutStyle = () => {
    return isLoading()
      ? {
        ...defaultLayouts.vStack.align("center", "center"),
        ...defaultLayouts.fill,
      } : {
        ...defaultLayouts.vStack.align("center", "flex-start"),
        ...defaultLayouts.fullWidth,
        ...defaultPadding.xxLargePad.apply("vertical"),
      };
  };

  return (
    <create-account style={getLayoutStyle()}>
      <GSListPage { ...getCreateAccountPage() } />
    </create-account>
  );
};

export default CreateAccount;
