import React, {useEffect, useState} from 'react';
import './golfstatus-login.scss';
import {
  GSAppLayout,
  GSAppNavigationHeader,
  GSPageBanner
} from "golfstatus_react_components";
import {Route, Routes, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import SignIn from "./sign-in";
import {useDispatch, useSelector} from "react-redux";
import {clearNotifications, selectNotifications, selectReferrer, setReferrer} from "../reducers/appSlice";
import {useNotificationBanner} from "../hooks/notificationHooks";
import logo from "../images/GSLogo.svg";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import CreateAccount from "./create-account";
import ChangePassword from "./change-password";
import ResetPassword from "./reset-password";
import {useIntercom} from "react-use-intercom";
import {messages, tmsQuickStartURL} from "../app/api";
import {getCookiePolicyStatus, getCurrentUser, getReferrer, setCookiePolicyCookie} from "../app/gs-session";
import CookiePolicy from "./cookie-policy";

const GolfstatusLogin = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const CaT = searchParams.get("cat") === "true";

  const {show} = useIntercom();

  const notifications = useSelector(selectNotifications)?.filter?.(n => !["sendResetPasswordEmail", "invalidResetPasswordToken"].includes(n.id) || n.result === "fulfilled");
  const referrer = useSelector(selectReferrer);

  const [hideEnvironment, setHideEnvironment] = useState(false);
  const [showCookieTray, setShowCookieTray] = useState(false);

  // Effects
  useEffect(() => {
    const ref = CaT ? tmsQuickStartURL : getReferrer();

    if (!CaT && getCurrentUser()) {
      window.location.replace(ref);
    } else if (!referrer) {
      dispatch(setReferrer(ref));
    }
  }, [dispatch, referrer, CaT]);

  useEffect(() => {
    if (!getCookiePolicyStatus()) {
      setShowCookieTray(true);
    }
  }, []);

  // Methods and content
  const getRouter = () => {
    return (
      <Routes>
        <Route path={"/"} element={<SignIn />} >
          <Route path={"reset-password"} element={<ResetPassword />} />
        </Route>
        <Route path={"/create"} element={<CreateAccount />} />
        <Route path={"/users/password/edit"} element={<ChangePassword />} />
      </Routes>
    );
  };

  const getTitle = () => {
    return (
      <GSAppNavigationHeader
        logo={logo}
        title="GOLFSTATUS"
      ></GSAppNavigationHeader>
    );
  };

  const getActiveRoute = () => {
    return `${pathname}`;
  };

  const hideNavEnvironment = () => {
    setHideEnvironment(true);
  };

  const getEnvironmentActions = () => {
    return [
      {
        actionIcon: faTimesCircle,
        type: "navigation",
        size: "secondary",
        actionClick: hideNavEnvironment,
      },
    ];
  };

  const getShowEnvironment = () => {
    if (hideEnvironment) {
      return false;
    }
    return (
      process.env.REACT_APP_ENV !== "production" &&
      process.env.REACT_APP_ENV !== "demo"
    );
  };

  const getNav = () => {
    return {
      navItems: [],
      title: getTitle(),
      activeRoute: getActiveRoute(),
      showEnv: getShowEnvironment(),
      environmentActions: getEnvironmentActions(),
      environment: `${process.env.REACT_APP_ENV} environment`,
      environmentDescription: process.env.REACT_APP_GOLFSTATUS_BASE_API_URL,
    };
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getNavigationActions = () => {
    return [
      {
        title: "Clear",
        size: "secondary",
        onClick: timeoutAction,
      },
    ];
  };

  const getNotificationActions = (notification) => {
    if (notification.id === "changePassword" && notification.result === "rejected") {
      if (notification?.message === messages.userBanned) {
        return [
          {
            id: 0,
            title: "Contact Support",
            type: "orange",
            actionClick: () => show(),
          },
        ];
      } else if (notification?.message === messages.resetTokenFailure) {
        return [
          {
            id: 0,
            title: "Get a New Link",
            type: "orange",
            actionClick: () => {
              dispatch(clearNotifications());
              navigate("/reset-password");
            },
          },
        ];
      }
    }
    return notification.pageActions;
  };

  const filteredNotifications = notifications?.filter?.(n => n.result !== "fulfilled" || "sendResetPasswordEmail" === n.id);

  const [bannerNotifications] = useNotificationBanner({
    notifications: filteredNotifications?.map?.((notification) => ({
      ...notification,
      bannerActions: getNotificationActions(notification),
    })),
    navigationActions: getNavigationActions(),
    timeoutAction,
  });

  const acceptCookiePolicy = () => {
    setCookiePolicyCookie();
    setShowCookieTray(false);
  };

  return (
    <golfstatus-login>
      <GSAppLayout
        navigation={"top"}
        nav={getNav()}
        notification={
          notifications?.length > 0 ? (
            <GSPageBanner {...bannerNotifications} />
          ) : false
        }
        routes={getRouter()}
      ></GSAppLayout>
      {showCookieTray && <CookiePolicy handleAccept={acceptCookiePolicy} />}
    </golfstatus-login>
  )
};

export default GolfstatusLogin;
