import {
  defaultTheme,
  invertSyle,
  mergedTheme,
} from "golfstatus_react_components";
import { useSelector } from "react-redux";
import { selectTheme } from "../reducers/appSlice";
/**
 * use the theming engine
 *
 * @typedef style
 * @type {object}
 * @property {object} light
 * @property {object} dark
 *
 * @typedef theme
 * @type {object}
 * @property {style} primary
 * @property {style} secondary
 * @property {style} primaryContainer
 * @property {style} secondaryContainer
 * @property {style} background
 * @property {style} surface
 * @property {style} surfaceDim
 * @property {style} surfaceLight
 * @property {style} surfaceContainer
 * @property {style} surfaceContainerLow
 * @property {style} surfaceContainerLowest
 * @property {style} surfaceContainerHigh
 * @property {style} surfaceContainerHighest
 * @property {style} surfaceVariant
 * @property {style} outline
 * @property {style} outlineVariant
 * @property {style} scrim
 * @property {style} error
 * @property {style} errorContainer
 * @property {style} tertiary
 * @property {style} tertiaryContainer
 *
 * @return {[theme, theme, getThemeStyle: object]}
 */
export const useTheme = () => {
  const currentMode = useSelector(selectTheme);
  const customTheme = mergedTheme({});
  const getThemeStyle = (style, invert) => {
    if (invert) {
      return invertSyle(style?.[currentMode]);
    }
    return style?.[currentMode];
  };
  return [defaultTheme, customTheme, getThemeStyle];
};
