import React, {useEffect, useState} from 'react';
import './sign-in.scss';
import {
  defaultGapping,
  defaultLayouts, defaultPadding,
  defaultTypography, golfstatusSpacing, golfstatusTypography, GSButton,
  GSForm, GSListPage, GSLoadingSpinnerOverlay, useFormDataValidation,
} from 'golfstatus_react_components';
import {getSignInFormSections} from "../forms/LoginForms";
import {useDispatch, useSelector} from "react-redux";
import {
  authenticateUser, authenticateUserWithApple, clearNotifications, selectLoading, selectNotifications, selectReferrer,
  selectSignInData,
  setSignInData,
} from "../reducers/appSlice";
import {useTheme} from "../hooks/themeHooks";
import {Outlet, useMatch, useNavigate, useSearchParams} from "react-router-dom";
import appleLogo from "../images/apple_logo.png";
import {logOut} from "../app/gs-session";
import {useNotificationAction} from "../hooks/notificationHooks";

const SignIn = () => {
  const [theme, , getStyle] = useTheme();
  const match = useMatch("/*");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const loading = useSelector(selectLoading);
  const notifications = useSelector(selectNotifications);
  const signInDetails = useSelector(selectSignInData);
  const referrer = useSelector(selectReferrer);

  const [redirecting, setRedirecting] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  // Set context
  const [context, isValid] = useFormDataValidation({
    initialValid: false,
    data: signInDetails,
    setData: (updatedSignInDetails) => dispatch(setSignInData(updatedSignInDetails)),
  });
  context.getHelp = () => {
    return {
      value: (
        <div style={{ ...defaultLayouts.hStack, ...defaultGapping.smallGap, flexWrap: "wrap" }}>
          <span style={{ opacity: 0.7 }}>Having trouble getting signed in?</span>
          <span style={{ ...getStyle(theme.secondary), fontWeight: golfstatusTypography.boldFontWeight, cursor: "pointer", textDecoration: "underline", textUnderlineOffset: "2px" }}
                onClick={ () => clearNotificationsAndNavigate("/reset-password") }>Get help.</span>
        </div>
      ),
    };
  }
  context.getSubmitButton = () => {
    return {
      value: (<GSButton title={"Sign In"} type={"black"} isDisabled={!isValid} onClick={authenticate} isFocusable={true} />),
    };
  };
  context.showPassword = showPassword;
  context.togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  context.onSubmit = () => {
    if (isValid) {
      authenticate();
    }
  };

  // Effects
  useEffect(() => {
    const CaT = searchParams.get("cat") === "true";
    const returningUser = searchParams.get("ru") === "true";

    if (CaT) {
      if (returningUser) {
        logOut();
        searchParams.set("ru", "false");
        navigate(`/?${searchParams.toString()}`);
      }
    }
  }, [dispatch, navigate, searchParams]);

  // We are hiding this on the other pages, so we need to show it here
  useEffect(() => {
    const captchaBadge = window.document.getElementsByClassName('grecaptcha-badge');
    if (captchaBadge[0]) {
      captchaBadge[0]['style']['display'] = 'block';
    }
  }, []);

  // Notification hooks
  useNotificationAction("fulfilled", notifications, () => window.location.replace(referrer), "authenticateUser");
  useNotificationAction("rejected", notifications, () => setRedirecting(false), "authenticateUser");
  useNotificationAction("fulfilled", notifications, () => window.location.replace(referrer), "authenticateUserWithApple");
  useNotificationAction("rejected", notifications, () => setRedirecting(false), "authenticateUserWithApple");

  // Methods and content
  const authenticate = () => {
    setRedirecting(true);
    // eslint-disable-next-line no-undef
    grecaptcha.enterprise.ready(async () => {
      // eslint-disable-next-line no-undef
      const captcha_token = await grecaptcha.enterprise.execute(process.env.REACT_APP_CAPTCHA_SCORE_KEY, { action: 'LOGIN' });
      dispatch(authenticateUser({ ...signInDetails, captcha_data: { captcha_token: captcha_token, captcha_action: 'LOGIN', is_score: true }}));
    });
  };

  const authenticateWithApple = () => {
    setRedirecting(true);
    dispatch(authenticateUserWithApple());
  };

  const getTitle = () => {
    return (
      <div style={{ ...defaultLayouts.vStack, ...defaultGapping.mediumGap }}>
        <div style={{ ...defaultTypography.displaySmall }}>Sign In</div>
        <div style={{ fontSize: golfstatusTypography.smallFont }}>
          <span style={{ opacity: 0.7, fontWeight: golfstatusTypography.regularFontWeight }}>New to GolfStatus? </span>
          <span style={{ ...getStyle(theme.secondary), cursor: "pointer", textDecoration: "underline", textUnderlineOffset: "2px" }} onClick={() => clearNotificationsAndNavigate("/create")}>Create Account.</span>
        </div>
      </div>
    );
  };

  const clearNotificationsAndNavigate = (path) => {
    dispatch(clearNotifications());
    navigate(path);
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const isSidePanelOpen = () => {
    return match && match?.params?.["*"] === "reset-password";
  };

  const isLoading = () => {
    return loading.length > 0 || redirecting;
  };

  const getContent = () => {
    return isLoading() ? (
        <div key={"spinner"} style={{...defaultLayouts.vStack.align("center", "center"), ...defaultLayouts.fill}}>
          <GSLoadingSpinnerOverlay spinnerSize={"xLarge"} mainText={"Loading..."} />
        </div>
      ) : [(
        <GSForm
          key={"sign-in-form"}
          style={{ ...defaultPadding.xLargePad }}
          formTitle={ getTitle() }
          formSections={ getSignInFormSections(context) }
        ></GSForm>
      ), (
        <div key={"apple-sign-in"} style={{ ...defaultLayouts.vStack.align("center", "flex-start"), gap: golfstatusSpacing.mediumLargePad, paddingTop: golfstatusSpacing.mediumLargePad }}>
          <div style={{ ...defaultTypography.tertiary }}>Continue with</div>
          <div><img alt={"Apple Logo"} style={{ width: "44px", height: "44px", cursor: "pointer" }} src={appleLogo} onClick={() => authenticateWithApple()} /></div>
        </div>
      )];
  };

  const getSignInPage = () => {
    return {
      getItemList: getContent,
      getRouter: getRouter,
      isSidePanelOpen: isSidePanelOpen,
      headerStyle: { ...defaultLayouts.hidden },
      style: { maxWidth: "480px", ...defaultLayouts.fullWidth },
    };
  };

  const getLayoutStyle = () => {
    return isLoading()
      ? {
        ...defaultLayouts.vStack.align("center", "center"),
        ...defaultLayouts.fill,
      } : {
        ...defaultLayouts.vStack.align("center", "flex-start"),
        ...defaultLayouts.fullWidth,
        ...defaultPadding.xxLargePad.apply("vertical"),
      };
  };

  return (
    <sign-in style={ getLayoutStyle() }>
      <GSListPage { ...getSignInPage() } />
    </sign-in>
  );
};

export default SignIn;
