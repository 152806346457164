import { Serializer as JSONAPISerializer } from 'jsonapi-serializer'

/**
 * Not all of these methods are being used currently, they are copied over from TMS app.
 * The delay constants are used to timebox notifications
 */
export const shortDelay = 2000;
export const mediumDelay = 5000;
export const longDelay = 10000;
export const extraLongDelay = 15000;

export const getAttributes = (object) => {
  return { attributes: Object.getOwnPropertyNames(object).filter((name) => name !== 'id') };
};

export const getErrors = (action) => {
  let errors = "Unable to authenticate";
  if (action?.payload?.errors?.length > 0) {
    errors = action.payload.errors.map(e => `${e.source?.pointer} : ${e.detail ?? e.error}`).join(', ');
  }
  return errors;
};

export const getResponse = async (method, url, serializedData, config, thunkAPI) => {
  try {
    return await method(url, serializedData, config);
  } catch (err) {
    return { ...thunkAPI.rejectWithValue(err.response.data), code: err.code, status: err.response.status };
  }
};

export const getSerializedData = (data, type, opts) => {
  const dataSerializer = new JSONAPISerializer(type, opts);
  const serializedData = dataSerializer.serialize(data);
  if (serializedData) {
    return serializedData;
  }
  return {};
};
