import Cookies from "js-cookie";

export const getCurrentUser = () => {
  const gsSessionCookie = Cookies.get("golfstatus-session");
  let gsSessionUser = {};
  if (gsSessionCookie) {
    gsSessionUser = JSON.parse(gsSessionCookie).authenticated;
    return Object.keys(gsSessionUser).length > 0 ? gsSessionUser : null;
  } else {
    return null;
  }
};

export const getReferrer = () => {
  // default to profile app
  const referrer = window.document.referrer;
  return isGolfstatusDomain(referrer) ? referrer : process.env.REACT_APP_GOLFSTATUS_PROFILE_APP_URL;
};

export const setAuthenticationCookie = (user, domain) => {
  const sessionData = JSON.stringify({
    authenticated: {
      authenticator: "authenticator:custom",
      ...snakeToCamelCase(user),
    }
  });
  Cookies.set("golfstatus-session", sessionData, { domain: domain, expires: 32 });
};

export function logOut() {
  Cookies.remove('golfstatus-session', {
    domain: process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN,
  });
  let logoutRedirectUrl = "/";
  if (Cookies.get('golfstatus-previous-link')) {
    let previousLink = JSON.parse(Cookies.get('golfstatus-previous-link'));
    if (previousLink && previousLink.linkName) {
      logoutRedirectUrl = previousLink.linkUrl;
    }
  }
  return logoutRedirectUrl;
}

export const setCookiePolicyCookie = () => {
  Cookies.set("golfstatus-cookie", true);
};

export const getCookiePolicyStatus = () => {
  return Cookies.get("golfstatus-cookie");
};

const isGolfstatusDomain = (url) => {
  return url?.toLowerCase().includes(process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN)
    && url !== window.location.href;
}

const snakeToCamelCase = (obj) => {
  return Object.entries(obj)
    .reduce((acc, [key, value]) => {
      const newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
      return { ...acc, [newKey]: value };
    }, {});
};
